const videoModule = () => import(/* webpackChunkName: 'components/video' */'../../components/video/video');
const seeMoreModule = () => import(/* webpackChunkName: 'components/seeMore' */'../../components/seeMore/seeMore');

$(() => {
  seeMoreModule().then(({ seeMore }) => { seeMore.init(); });
  videoModule().then(({video}) => {new video();});

  if ($('.js-fancyboxVideo').length) {
    new FancyboxView({
      selector: '.js-fancyboxVideo',
      popinName: 'home media video',
      fancyType: 'custom',
      options: {
        buttons: ['close'],
        overlayClose: false,
        isModal: false,
        preventScroll: true,
        clickSlide: false,
        clickOutside: false,
        touch: true,
        loop: true,
        baseClass: 'fancybox-skin--white fancybox-skin--white_slide-in-top'
      }
    });
  }
});